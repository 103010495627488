import FetcherFactory from 'utils/FetcherFactory';
import { Knowledge } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchKnowledgesResponse = {
  data: Knowledge[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};
const fetchAllKnowledge = async ({
  size = 9,
  page = 1,
}: { size?: number; page?: number } = {}): Promise<FetchKnowledgesResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.withPublicToken().get(`/api/knowledges`, {
    'pagination[pageSize]': size,
    'pagination[page]': page,
    'populate[cover][fields]': '*',
    'populate[localizations][fields][0]': 'title',
    'populate[localizations][fields][1]': 'content',
    'populate[localizations][fields][2]': 'locale',
  });
  const data = await res.json();
  return data;
};

export default fetchAllKnowledge;
