import { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Button, Checkbox, FormGroup } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { MEDIA_SM, MEDIA_MD } from 'styles/media';
import { register, createCheckout } from 'api';
import { AppToaster, RadioButtons } from 'components';
import { useRefreshMe } from 'hooks';
import { setAuthToken, setRenewToken, getRefreshToken } from 'state/app';
import { getAllActiveSubscriptionPlan } from 'state/plans';
import { AccountType, PlanTargetType } from '@type';
import { locale2Lang } from 'utils/i18n';

import Tracker from 'utils/tracker';

const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 18px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin-bottom: 0;
  }
`;
const Description = styled.p`
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #222222;
  opacity: 0.4;
`;

const InputField: typeof TextField = styled(TextField)`
  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }
  label.Mui-error {
    color: #d32f2f;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }
  .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
`;
const StyledFormControl = styled(FormControl)`
  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }
  label.Mui-error {
    color: #d32f2f;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }
  .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
`;
const RegisterForm = styled.form`
  margin-top: 50px;
  color: ${({ theme }) => theme.primary.text};
  max-width: 360px;
  text-align: left;

  @media ${MEDIA_MD} {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 80px;
  }

  > div + div {
    margin-top: 20px;
  }
`;
const FormRemark = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .bp4-control {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .bp4-control input:checked ~ .bp4-control-indicator {
    background-color: ${({ theme }) => theme.success.text};
  }
`;
const SubmitButton = styled(Button)`
  margin-top: 28px;

  &.bp4-button {
    height: 60px;
    background: ${({ theme }) => theme.success.text};
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    line-height: 21px;
  }

  &.bp4-button:hover {
    background: ${({ theme }) => theme.success.textHover};
  }
`;
const LeftFoot = styled.div`
  margin-top: 40px;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #abafb3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoginButton = styled(Link)`
  margin-left: 4px;
  font-size: inherit;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
  color: #ffae10 !important;
`;

// eslint-disable-next-line complexity
const RegisterPage: FC = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const plans = useSelector(getAllActiveSubscriptionPlan);
  const isLogin = Boolean(useSelector(getRefreshToken));
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const refreshMe = useRefreshMe();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .email()
          .required(t('validate.required', { ns: 'errors' })),
        password: yup
          .string()
          .required(t('validate.required', { ns: 'errors' }))
          .min(8),
        confirmPassword: yup
          .string()
          .required(t('validate.required', { ns: 'errors' }))
          .oneOf(
            [yup.ref('password')],
            t('validate.samePassword', { ns: 'errors' }),
          ),
        accountType: yup.string().required(),
        planId: yup.number().nullable(),
        agree: yup
          .boolean()
          .oneOf([true], t('validate.agreePolicy', { ns: 'errors' })),
      }),
    [i18n.language],
  );
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
      confirmPassword: '',
      accountType:
        locationState?.accountType === 'company'
          ? AccountType.COMPANY
          : AccountType.INDIVIDUAL,
      planId: locationState?.planId || null,
      agree: false,
    },
    validateOnChange: true,
    validationSchema: schema,
    onSubmit: async ({ username, password, accountType, planId }) => {
      try {
        setLoading(true);
        const res = await register({
          username,
          rawPassword: password,
          accountType,
        });
        dispatch(setAuthToken(res.accessToken));
        dispatch(
          setRenewToken({
            token: res.refreshToken,
            presist: true,
          }),
        );
        await refreshMe();
        navigate('/kyc', { state: { planId } });
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (isLogin) {
      if (locationState?.planId) {
        (async () => {
          try {
            const result = await createCheckout(locationState.planId);
            window.location.assign(result.url);
          } catch (e: any) {
            AppToaster.apiError(e);
            navigate('/user', { replace: true });
          }
        })();
        return;
      }
      navigate('/user', { replace: true });
    } else {
      Tracker.track('Visited Register Page');
    }
    // only redirect when on mount
  }, []);

  return (
    <>
      <Title>{t('pages.registerPage.title')}</Title>
      <Description>{t('pages.registerPage.description')}</Description>
      <RegisterForm onSubmit={handleSubmit}>
        <InputField
          variant="outlined"
          label={t('forms.login.email')}
          name="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.username && Boolean(errors.username)}
          fullWidth
          helperText={touched.username ? errors.username : ''}
          autoComplete="email"
        />
        <InputField
          type={visible ? 'text' : 'password'}
          variant="outlined"
          label={t('forms.login.password')}
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          fullWidth
          helperText={touched.password ? errors.password : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  minimal
                  onClick={() => setVisible((v) => !v)}
                  icon={visible ? 'eye-open' : 'eye-off'}
                />
              </InputAdornment>
            ),
          }}
        />
        <InputField
          type={visible2 ? 'text' : 'password'}
          variant="outlined"
          label={t('forms.register.confirmPassword')}
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
          fullWidth
          helperText={touched.confirmPassword ? errors.confirmPassword : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  minimal
                  onClick={() => setVisible2((v) => !v)}
                  icon={visible2 ? 'eye-open' : 'eye-off'}
                />
              </InputAdornment>
            ),
          }}
        />
        <RadioButtons
          name="accountType"
          label={t('forms.register.accountType')}
          value={values.accountType}
          onChange={(n, v) => {
            setFieldValue(n, v);
            const plan = plans.find((p) => p.id === values.planId);
            if (plan?.attributes?.target_type === PlanTargetType.ALL) return;
            setFieldValue('planId', null);
          }}
          options={[
            {
              label: t('accountType.individual'),
              value: AccountType.INDIVIDUAL,
            },
            { label: t('accountType.company'), value: AccountType.COMPANY },
          ]}
        />
        <StyledFormControl fullWidth>
          <InputLabel>{t('forms.register.plan')}</InputLabel>
          <Select
            name="planId"
            value={values.planId}
            label={t('forms.register.plan')}
            fullWidth
            onChange={handleChange}
          >
            {plans
              .filter((p) =>
                [PlanTargetType.ALL, values.accountType as string].includes(
                  p.attributes.target_type,
                ),
              )
              .map((p) => {
                const langData = p.attributes.localizations.data.find(
                  (x) => x.attributes.locale === locale2Lang(i18n.language),
                );
                return (
                  <MenuItem value={p.id} key={p.id}>
                    <span>
                      {langData?.attributes.name || p.attributes.name}
                    </span>
                  </MenuItem>
                );
              })}
          </Select>
        </StyledFormControl>
        <FormRemark>
          <FormGroup
            intent={errors.agree && touched.agree ? 'danger' : undefined}
            helperText={touched.agree && errors.agree}
          >
            <Checkbox
              name="agree"
              checked={values.agree}
              labelElement={
                <span>
                  <Trans
                    i18nKey="forms.register.agreement"
                    defaults="By creating your account, you agree to our <terms>Terms and Conditions</terms> & <policy>Privacy Policy</policy>"
                    components={{
                      terms: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a href="/terms" target="_blank" aria-label="terms" />
                      ),
                      policy: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href="/privacy"
                          target="_blank"
                          aria-label="policy"
                        />
                      ),
                    }}
                  />
                </span>
              }
              onChange={handleChange}
            />
          </FormGroup>
        </FormRemark>
        <SubmitButton
          type="submit"
          large
          fill
          loading={loading}
          text={t('forms.register.register')}
        />
        <LeftFoot>
          <Trans
            i18nKey="forms.register.login"
            defaults="Already have an account? <login>Login here</login>"
            components={{
              login: <LoginButton to="/login" />,
            }}
          />
        </LeftFoot>
      </RegisterForm>
    </>
  );
};

export default RegisterPage;
