import { FC, useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField, InputAdornment } from '@mui/material';
import { Button, Checkbox } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { MEDIA_SM, MEDIA_MD } from 'styles/media';
import { login } from 'api';
import { AppToaster } from 'components';
import { useRefreshMe } from 'hooks';
import { setAuthToken, setRenewToken } from 'state/app';

import useRedirectUserEffect from './useRedirectUserEffect';

const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 18px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin-bottom: 0;
  }
`;
const Description = styled.p`
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #222222;
  opacity: 0.4;
`;

const InputField: typeof TextField = styled(TextField)`
  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }
  label.Mui-error {
    color: #d32f2f;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }
  .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
`;
const LoginForm = styled.form`
  margin-top: 50px;
  color: ${({ theme }) => theme.primary.text};
  max-width: 360px;

  @media ${MEDIA_MD} {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 80px;
  }

  ${InputField as any} + ${InputField as any} {
    margin-top: 46px;

    @media ${MEDIA_MD} {
      margin-top: 24px;
    }
  }
`;
const FormRemark = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .bp4-control {
    margin-bottom: 0;
  }

  .bp4-control input:checked ~ .bp4-control-indicator {
    background-color: ${({ theme }) => theme.success.text};
  }
`;
const ForgetButton = styled(Link)`
  font-family: 'Open Sans';
  font-size: 16px;
  color: ${({ theme }) => theme.success.text} !important;
`;
const LoginButton = styled(Button)`
  margin-top: 28px;

  &.bp4-button {
    height: 60px;
    background: ${({ theme }) => theme.success.text};
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    line-height: 21px;
  }

  &.bp4-button:hover {
    background: ${({ theme }) => theme.success.textHover};
  }
`;
const LeftFoot = styled.div`
  margin-top: 40px;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #abafb3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RegisterButton = styled(Link)`
  margin-left: 4px;
  font-size: inherit;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
  color: #ffae10 !important;
`;

const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const refreshMe = useRefreshMe();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .email()
          .required(t('validate.required', { ns: 'errors' })),
        password: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        remember: yup.boolean(),
      }),
    [i18n.language],
  );
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        username: '',
        password: '',
        remember: false,
      },
      validateOnChange: true,
      validationSchema: schema,
      onSubmit: async ({ username, password, remember }) => {
        try {
          setLoading(true);
          const res = await login(username, password);
          dispatch(setAuthToken(res.accessToken));
          dispatch(
            setRenewToken({
              token: res.refreshToken,
              presist: remember,
            }),
          );
          refreshMe();
          navigate('/user');
        } catch (e: any) {
          AppToaster.apiError(e);
        } finally {
          setLoading(false);
        }
      },
    });

  useRedirectUserEffect();

  return (
    <>
      <Title>{t('pages.loginPage.title')}</Title>
      <Description>{t('pages.loginPage.description')}</Description>
      <LoginForm onSubmit={handleSubmit}>
        <InputField
          variant="outlined"
          label={t('forms.login.email')}
          name="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.username && Boolean(errors.username)}
          fullWidth
          helperText={touched.username ? errors.username : ''}
          autoComplete="email"
        />
        <InputField
          type={visible ? 'text' : 'password'}
          variant="outlined"
          label={t('forms.login.password')}
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          fullWidth
          helperText={touched.password ? errors.password : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  minimal
                  onClick={() => setVisible((v) => !v)}
                  icon={visible ? 'eye-open' : 'eye-off'}
                />
              </InputAdornment>
            ),
          }}
        />
        <FormRemark>
          <Checkbox
            name="remember"
            label={t('forms.login.remember')}
            checked={values.remember}
            onChange={handleChange}
          />
          <ForgetButton to="/forget">
            {t('forms.login.forgetPassword')}
          </ForgetButton>
        </FormRemark>
        <LoginButton
          loading={loading}
          type="submit"
          large
          fill
          text={t('forms.login.login')}
        />
        <LeftFoot>
          <Trans
            i18nKey="forms.login.register"
            defaults="Don’t have an account? <reg>Register Now</reg>"
            components={{
              reg: <RegisterButton to="/register" />,
            }}
          />
        </LeftFoot>
      </LoginForm>
    </>
  );
};

export default LoginPage;
