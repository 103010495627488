import { ChangeEvent, FC, useCallback, useMemo, FocusEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';

import { getQuestion } from 'state/questions';

import { FormErrors, FormTouched } from '@type';
import { locale2Lang } from 'utils/i18n';
import TextField from '../inputs/TextField';
import SuggestField from '../inputs/SuggestField';
import DateField from '../inputs/DateField';
import SelectField from '../inputs/SelectField';
import SignatureField from './SignatureField';
// eslint-disable-next-line import/no-cycle
import GroupField from './GroupField';
// eslint-disable-next-line import/no-cycle
import RadioField from './RadioField';
import InfoTooltip from '../InfoTooltip';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  > div {
    flex: 1;
  }
`;
const StyledTextField = styled(TextField)`
  &.bp4-form-group label.bp4-label {
    ${({ theme }) => theme.text.inputLabel}
    color: ${({ theme }) => theme.primary.label};
    opacity: 1;
  }
`;
const StyledSuggestField = styled(SuggestField)`
  &.bp4-form-group label.bp4-label {
    ${({ theme }) => theme.text.inputLabel}
    color: ${({ theme }) => theme.primary.label};
    opacity: 1;
  }
`;
const StyledDateField = styled(DateField)`
  &.bp4-form-group label.bp4-label {
    ${({ theme }) => theme.text.inputLabel}
    color: ${({ theme }) => theme.primary.label};
    opacity: 1;
  }
`;
const StyledSelectField = styled(SelectField)`
  &.bp4-form-group label.bp4-label {
    ${({ theme }) => theme.text.inputLabel}
    color: ${({ theme }) => theme.primary.label};
    opacity: 1;
  }
`;
const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: 4px;
`;
const EditButton = styled(Button)`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-top: 8px;
`;

type Props<T extends Record<string, any>> = {
  className?: string;
  questionId: number;
  prefix?: string;
  overrideName?: string;
  name: string;
  value?: T;
  touched?: FormTouched<T>;
  error?: FormErrors<T>;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (name: string, value: any) => void;
  onBlur?: (name: string) => void;
  onClear?: (name: string) => void;
  onEditClick?: () => void;
};
// eslint-disable-next-line complexity
const QuestionRender: FC<Props<any>> = ({
  className,
  questionId,
  prefix = '',
  overrideName,
  name,
  value,
  touched,
  error,
  disabled,
  readonly,
  onChange,
  onBlur,
  onClear,
  onEditClick,
}) => {
  const { i18n } = useTranslation();
  const question = useSelector(getQuestion(questionId));
  const type = question?.attributes?.type || '';
  const subName = overrideName || `${prefix}${question?.attributes.name || ''}`;
  const currentName = [name, subName].filter(Boolean).join('.');
  const groupQuestion = useMemo(
    () =>
      question?.attributes?.groupMeta?.questions?.data?.map((d) => d.id) || [],
    [question],
  );
  const dateForm = useMemo(
    () =>
      question?.attributes?.dateMeta?.from
        ? new Date(question?.attributes?.dateMeta?.from)
        : undefined,
    [question],
  );
  const dateTo = useMemo(
    () =>
      question?.attributes?.dateMeta?.to
        ? new Date(question?.attributes?.dateMeta?.to)
        : undefined,
    [question],
  );
  const handleEventChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.name, e.target.value);
    },
    [onChange],
  );
  const handleEventBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e.target.name);
  }, []);
  if (!question) {
    return null;
  }

  const langData = question.attributes.localizations.data.find(
    (q) => q.attributes.locale === locale2Lang(i18n.language),
  );

  const tooltip = langData?.attributes.tooltip || question.attributes.tooltip;
  const textLabel =
    langData?.attributes.displayLabel || question.attributes.displayLabel;
  const label = textLabel ? (
    <span>
      <span>{textLabel}</span>
      {Boolean(tooltip) && <StyledInfoTooltip content={String(tooltip)} />}
    </span>
  ) : undefined;

  let Content = <div>Not Implement</div>;

  if (['Text', 'Number'].includes(type)) {
    Content = (
      <StyledTextField
        solid
        label={label}
        name={currentName}
        type={type === 'Number' ? 'number' : 'text'}
        value={value?.[subName]}
        touched={touched?.[subName] as boolean}
        error={error?.[subName] as string}
        onBlur={handleEventBlur}
        onChange={handleEventChange}
        disabled={disabled}
        readonly={readonly}
        autoComplete={
          question.attributes.auto_complete_group?.data?.attributes?.label ||
          undefined
        }
        placeholder={
          langData?.attributes.textMeta?.placeholder ||
          question.attributes?.textMeta?.placeholder ||
          undefined
        }
      />
    );
    if (
      type === 'Text' &&
      question.attributes.auto_complete_group?.data?.attributes?.label
    ) {
      Content = (
        <StyledSuggestField
          solid
          label={label}
          name={currentName}
          value={value?.[subName]}
          touched={touched?.[subName] as boolean}
          error={error?.[subName] as string}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          readonly={readonly}
          autoComplete={
            question.attributes.auto_complete_group.data.attributes.label
          }
          placeholder={
            langData?.attributes.textMeta?.placeholder ||
            question.attributes?.textMeta?.placeholder ||
            undefined
          }
        />
      );
    }
  }
  if (type === 'Select') {
    Content = (
      <StyledSelectField
        label={label}
        name={currentName}
        value={value?.[subName]}
        touched={touched?.[subName] as boolean}
        error={error?.[subName] as string}
        onBlur={(e) => onBlur?.(e.target.name)}
        onChange={onChange}
        disabled={disabled}
        readonly={readonly}
        multi={question.attributes.selectMeta?.multiple}
        options={question.attributes.selectMeta?.options || []}
        langOptions={langData?.attributes.selectMeta?.options}
        allLangOptions={Object.fromEntries(
          question.attributes.localizations.data.map((la) => [
            la.attributes.locale,
            la.attributes.selectMeta?.options || [],
          ]),
        )}
      />
    );
  }

  if (type === 'Date') {
    Content = (
      <StyledDateField
        label={label}
        name={currentName}
        value={value?.[subName]}
        touched={touched?.[subName] as boolean}
        error={error?.[subName] as string}
        onBlur={onBlur}
        onChange={onChange}
        from={dateForm}
        to={dateTo}
        disabled={disabled}
        readonly={readonly}
      />
    );
  }

  if (type === 'Group') {
    Content = (
      <GroupField
        label={label}
        name={currentName}
        value={value?.[subName]}
        touched={touched?.[subName] as FormTouched<any>}
        error={error?.[subName] as FormErrors<any>}
        onBlur={onBlur}
        onChange={onChange}
        onClear={onClear}
        questions={groupQuestion}
        multi={question.attributes.groupMeta?.multiple}
        maxItem={question.attributes.groupMeta?.maxItem}
        disabled={disabled}
        readonly={readonly}
      />
    );
  }

  if (type === 'Radio') {
    Content = (
      <RadioField
        label={label}
        name={name}
        subName={subName}
        value={value}
        touched={touched as FormTouched<any>}
        error={error as FormErrors<any>}
        onBlur={onBlur}
        onChange={onChange}
        onClear={onClear}
        disabled={disabled}
        readonly={readonly}
        options={question.attributes.radioMeta?.options}
        langOptions={langData?.attributes.radioMeta?.options}
        allLangOptions={Object.fromEntries(
          question.attributes.localizations.data.map((la) => [
            la.attributes.locale,
            la.attributes.radioMeta?.options || [],
          ]),
        )}
        multi={question.attributes.radioMeta?.multiple}
      />
    );
  }

  if (type === 'Signature') {
    Content = (
      <SignatureField
        label={label}
        name={currentName}
        value={value?.[subName]}
        touched={touched?.[subName] as boolean}
        error={error?.[subName] as string}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        readonly={readonly}
      />
    );
  }

  return (
    <Wrapper className={className}>
      {Boolean(onEditClick) && (
        <EditButton minimal outlined icon="arrow-left" onClick={onEditClick} />
      )}
      {Content}
    </Wrapper>
  );
};

export default QuestionRender;
