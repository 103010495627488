import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { FormGroup, Icon } from '@blueprintjs/core';
import { DateInput2 } from '@blueprintjs/datetime2';
import { format, parse } from 'date-fns';
import classnames from 'classnames';

import { MEDIA_SM } from 'styles/media';

const Value = styled.div`
  ${({ theme }) => theme.text.previewText};
`;

const StyleFormGroup = styled(FormGroup)`
  margin: 0;

  &.bp4-form-group.bp4-inline {
    align-items: center;
  }

  .bp4-form-content {
    flex: 1;
    margin-top: ${({ label }) => (label ? '4px' : 0)};
  }

  &.form-field--readonly .bp4-form-content {
    margin-bottom: ${({ label }) => (label ? '12px' : 0)};
  }

  .bp4-label {
    ${({ theme }) => theme.text.inputLabel};
  }

  .bp4-input-group {
    background: #f8f8f8;
    border-radius: 8px;
    font-family: 'Open Sans';
    height: 52px;
    box-shadow: none;
    padding: 14px;
    display: flex;
    align-items: center;

    @media ${MEDIA_SM} {
      height: 38px;
      padding: 8px;
      padding-left: 16px;
    }
  }

  .bp4-input-group .bp4-input-action {
    position: relative;
  }

  .bp4-input-group > .bp4-icon {
    position: relative;
    margin: 0;
  }

  input.bp4-input {
    ${({ theme }) => theme.text.inputValue};
    background: transparent;
    box-shadow: none;
    pading: 0;

    &:not(:first-child) {
      padding-left: 8px;
    }
  }
`;

const StyledDateInput = styled(DateInput2)`
  margin-top: 4px;

  input {
    background: none;
    color: ${({ theme }) => theme.primary.label};
  }

  .bp4-input:disabled {
    background: inherit;
    color: inherit;
  }
`;

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');
const parseDate = (str: string) => parse(str, 'yyyy-MM-dd', new Date());
type Props = {
  className?: string;
  label?: React.ReactNode;
  name?: string;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
  inline?: boolean;
  from?: Date | string;
  to?: Date | string;
  value?: string | null;
  onChange?: (n: string, v: string | null) => void;
  onBlur?: (n: string) => void;
};
const DateField: FC<Props> = ({
  className,
  label,
  name = '',
  touched,
  error,
  inline,
  disabled,
  readonly,
  value = null,
  from,
  to,
  onChange,
  onBlur,
}) => {
  const handleChange = useCallback(
    (v: string | null) => {
      if (onChange) onChange(name, v);
      // At the time blur, value is not set
      // use event cycle to trigger setFieldTouced
      if (onBlur) setTimeout(() => onBlur(name), 0);
    },
    [onChange],
  );
  return (
    <StyleFormGroup
      className={classnames(className, 'dateField', {
        'form-field--readonly': readonly,
      })}
      label={label}
      helperText={touched && error}
      intent={touched && error ? 'danger' : 'none'}
      inline={inline}
      disabled={disabled}
    >
      {readonly ? (
        <Value>{value}</Value>
      ) : (
        <StyledDateInput
          value={value}
          onChange={handleChange}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder="YYYY-MM-DD"
          minDate={from ? new Date(from) : undefined}
          maxDate={to ? new Date(to) : undefined}
          fill
          rightElement={<Icon icon="calendar" />}
        />
      )}
    </StyleFormGroup>
  );
};

export default DateField;
