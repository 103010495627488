import { FC, useState, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Dialog, DialogProps, Classes } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { MEDIA_SM } from 'styles/media';
import { Button, Checkbox } from 'components';
import ReminderMemo from 'utils/ReminderMemo';

const ProtalDialog = ({ className, ...rest }: DialogProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dialog {...rest} portalClassName={className} />
);
const StyledDialog = styled(ProtalDialog)`
  .bp4-dialog-container {
    align-items: flex-start;
  }

  .bp4-dialog {
    margin-top: 30vh;
  }

  .bp4-dialog-body {
    ${({ theme }) => theme.text.cardDescription};

    @media ${MEDIA_SM} {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
`;
const Content = styled.div`
  > * + * {
    margin-top: 8px;
  }
`;
const Footer = styled.div`
  justify-content: space-between;
  align-items: center;
`;

type Props = PropsWithChildren<{
  className?: string;
  docId?: number;
  open: boolean;
  onClose: () => void;
}>;
const ReminderDialog: FC<Props> = ({
  className,
  docId,
  open,
  children,
  onClose,
}) => {
  const { t } = useTranslation();
  const [skip, setSkip] = useState(false);

  return (
    <StyledDialog className={className} isOpen={open} onClose={onClose}>
      <Content className={Classes.DIALOG_BODY}>{children}</Content>
      <div className={Classes.DIALOG_FOOTER}>
        <Footer className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Checkbox
            checked={skip}
            onChange={(e) => setSkip(e.currentTarget.checked)}
            label={t('actions.dontShow')}
          />
          <StyledButton
            onClick={() => {
              onClose();
              if (skip && docId) {
                ReminderMemo.skip(docId);
              }
            }}
            intent="success"
            text={t('actions.understand')}
          />
        </Footer>
      </div>
    </StyledDialog>
  );
};

export default ReminderDialog;
