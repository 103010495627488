import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { merge } from 'lodash';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { FormErrors, FormTouched } from '@type';
import { tryDeepEmpty } from 'utils/question';

// eslint-disable-next-line import/no-cycle
import QuestionRender from './QuestionRender';

const Container = styled.div`
  margin: 0;

  > .bp4-form-group + .bp4-form-group {
    margin-top: 8px;
  }
`;
const Label = styled.label<{ hasLabel: boolean }>`
  display: block;
  min-height: ${({ hasLabel }) => (hasLabel ? '16px' : 0)};
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 8px;

  ${({ theme }) => theme.text.questionLabel};
`;
const QuestionBlock = styled.div`
  border: 1px solid;
  border-radius: 3px;
  border-color: rgba(0, 0, 0, 0.4);
  padding: 4px 12px;

  > .bp4-form-group + .bp4-form-group {
    margin-top: 8px;
  }
`;
const RemoveButton = styled(Button)``;
const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.error.text};
  font-size: ${({ theme }) => theme.font.sm};
  line-height: 1.1;
  word-break: keep-all;
`;

type Props<T = any> = {
  className?: string;
  label?: React.ReactNode;
  name: string;
  touched?: FormTouched<T>;
  error?: FormErrors<T>;
  readonly?: boolean;
  disabled?: boolean;
  value?: T;
  questions: number[];
  multi?: boolean;
  maxItem?: number | null;
  onChange?: (n: string, v: T) => void;
  onBlur?: (n: string) => void;
  onClear?: (n: string) => void;
};
const GroupField: FC<Props> = ({
  className,
  label,
  name,
  touched,
  error,
  readonly,
  disabled,
  value,
  questions,
  multi,
  maxItem,
  onChange,
  onBlur,
  onClear,
}) => {
  const { t } = useTranslation();
  const onAddClick = useCallback(() => {
    if (onChange) onChange(name, [...value, tryDeepEmpty(value?.[0])]);
  }, [onChange, value]);
  const onDeleteClick = useCallback(
    (idx: number) => {
      if (onChange) {
        onChange(name, [...value.slice(0, idx), ...value.slice(idx + 1)]);
      }
    },
    [onChange, value],
  );

  if (!multi) {
    return (
      <Container
        className={classnames(className, 'bp4-form-group', {
          'form-field--readonly': readonly,
        })}
      >
        <Label hasLabel={Boolean(label)}>{label}</Label>
        {questions.map((id) => (
          <QuestionRender
            key={id}
            questionId={id}
            name={name}
            touched={touched}
            error={error}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onClear={onClear}
            disabled={disabled}
            readonly={readonly}
          />
        ))}
      </Container>
    );
  }

  return (
    <Container
      className={classnames(className, 'bp4-form-group', {
        'form-field--readonly': readonly,
      })}
    >
      <Label hasLabel={Boolean(label)}>{label}</Label>
      {((value as any[]) || []).map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <QuestionBlock key={idx}>
          {!readonly && (
            <RemoveButton
              minimal
              prefix="delete"
              onClick={() => onDeleteClick(idx)}
              disabled={idx === 0 || disabled}
            />
          )}
          {questions.map((id) => (
            <QuestionRender
              key={id}
              questionId={id}
              name={`${name}[${idx}]`}
              touched={touched?.[idx] as FormTouched<any>}
              // for add up sum error
              // -1 = all element for array
              error={merge(error?.[idx], error?.['-1']) as FormErrors<any>}
              value={value?.[idx]}
              onBlur={onBlur}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              readonly={readonly}
            />
          ))}
        </QuestionBlock>
      ))}
      {!readonly && !disabled && (
        <Button
          prefix="add"
          text={t('actions.add')}
          onClick={onAddClick}
          disabled={
            disabled ||
            (value?.length || 0) >= (maxItem || Number.MAX_SAFE_INTEGER)
          }
          intent="success"
        />
      )}
      {typeof error === 'string' && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default GroupField;
