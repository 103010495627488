import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@blueprintjs/core';

import { FAQ } from '@type';
import { fetchFAQs } from 'api/faq';
import { AppToaster } from 'components';
import { FAQNotFoundSection, ColorfulSection } from 'containers';
import { MEDIA_MD, MEDIA_SM, MEDIA_LG } from 'styles/media';
import Tracker from 'utils/tracker';
import { locale2Lang } from 'utils/i18n';

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding-bottom: 120px;
`;
const FrontBlock = styled.div`
  max-width: 1440px;
  margin: auto;
  margin-top: 180px;
  text-align: center;

  @media ${MEDIA_SM} {
    margin-top: 100px;
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  white-space: pre-line;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_MD} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  white-space: pre-line;
  max-width: 470px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;
const QuestionContainer = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 38px;
  row-gap: 20px;
  text-align: left;
  padding: 0 142px;

  @media ${MEDIA_LG} {
    padding: 0 24px;
  }

  @media ${MEDIA_MD} {
    grid-template-columns: 1fr;
  }

  @media ${MEDIA_SM} {
    margin-top: 20px;
  }
`;
const QuestionBlock = styled.div`
  font-family: 'Open Sans';

  > div:first-child {
    padding: 16px 32px;
    font-weight: 700;
    font-size: 19px;
    line-height: 130%;
    border: 2px solid;
    border-radius: 50px;
    color: #000000;

    @media ${MEDIA_SM} {
      padding: 12px 24px;
    }
  }

  > div:last-child {
    margin-top: 16px;
    padding: 0 40px;
    font-size: 16px;
    line-height: 170%;
    color: rgba(0, 0, 0, 0.63);

    @media ${MEDIA_SM} {
      padding: 0 24px;
    }
  }

  &:nth-child(6n + 1),
  &:nth-child(6n + 2) {
    > div:first-child {
      border-color: #d2f8d3;
    }
  }

  &:nth-child(6n + 3),
  &:nth-child(6n + 4) {
    > div:first-child {
      border-color: #f6e6b3;
    }
  }

  &:nth-child(6n + 5),
  &:nth-child(6n) {
    > div:first-child {
      border-color: #cfeef0;
    }
  }
`;

const StyledColorfulSection = styled(ColorfulSection)`
  margin-top: 120px;

  @media ${MEDIA_SM} {
    margin-top: 60px;
  }
`;
const StyledFAQNotFoundSection = styled(FAQNotFoundSection)`
  margin: auto;
  margin-top: 120px;

  @media ${MEDIA_SM} {
    margin-top: 60px;
  }
`;

const FAQPage: FC<{}> = () => {
  const { t, i18n } = useTranslation();
  const [faqs, setfaqs] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Tracker.track('Visited FAQ Page');
    (async () => {
      try {
        const res = await fetchFAQs();
        setfaqs(res.data);
      } catch (e: any) {
        AppToaster.apiError(e);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Container>
      <FrontBlock>
        <Header>{t('pages.faq.header')}</Header>
        <Title>{t('pages.faq.title')}</Title>
        <Description>{t('pages.faq.description')}</Description>
        <QuestionContainer>
          {loading && <Spinner />}
          {faqs.map((f) => {
            const langData = f.attributes.localizations?.data?.find(
              (x) => x.attributes.locale === locale2Lang(i18n.language),
            );
            return (
              <QuestionBlock key={f.id}>
                <div>{langData?.attributes.title || f.attributes.title}</div>
                <div>
                  {langData?.attributes.description || f.attributes.description}
                </div>
              </QuestionBlock>
            );
          })}
        </QuestionContainer>
      </FrontBlock>
      {/* <StyledMoreSection /> */}
      <StyledColorfulSection pageType="faq" />
      <StyledFAQNotFoundSection />
    </Container>
  );
};

export default FAQPage;
